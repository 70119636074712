















































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProfileTeoKk',
  // props: {
  // },
  components: {},
  setup: () => {
    return {};
  }
});
